'use client'

import SubHeader from '@components/SubHeader'
import React from 'react'

const NormalLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <SubHeader />
    {children}
  </>
)
export default NormalLayout
